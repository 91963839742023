import { create } from '@storybook/theming';
// import incognitoLogo from './images/incognito-logo.png';

export default create({
  base: 'light',
  brandTitle: 'Incognito',
  brandUrl: 'https://incognitoscore.com',
  // brandImage: incognitoLogo,
  brandTarget: '_blank',
});
